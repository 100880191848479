<template>
  <v-app>
    <EnvironmentSystemBar :environment="environment"> </EnvironmentSystemBar>
    <v-app-bar app dark>
      <SelectedProgram></SelectedProgram>
      <SelectedProgramDropdown class="pr-15"></SelectedProgramDropdown>
      <v-spacer></v-spacer>

      <country-flag v-if="this.selectedCountry.name == 'USA'" country="usa" size="normal" />
      <country-flag v-if="this.selectedCountry.name == 'CAN'" country="can" size="normal" />

      <LocaleDropdown :routeToDashboard="true"></LocaleDropdown>
      <UserDropdown></UserDropdown>
      <template v-slot:extension>
        <TopMenu></TopMenu>
      </template>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main class="login-bg">
      <Breadcrumbs></Breadcrumbs>
      <!-- Provides the application the proper gutter -->
      <v-container>
        <v-row justify="center">
          <v-col lg="6" md="8" sm="10" xs="12">
            here
            <router-view :key="$route.fullPath"></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <v-footer absolute>
      <Footer></Footer>
    </v-footer>
  </v-app>
</template>

<script>
import Footer from "../../components/Footer.vue";
import Breadcrumbs from "../../gapp-components/components/display/Breadcrumbs.vue";
import TopMenu from "../../components/TopMenu.vue";
import UserDropdown from "../../components/UserDropdown.vue";
import LocaleDropdown from "../../gapp-components/components/fields/LocaleDropdown.vue";
import SelectedProgramDropdown from "../../gapp-components/components/fields/SelectedProgramDropdown.vue";
import SelectedProgram from "../../components/SelectedProgram.vue";
import EnvironmentSystemBar from "../../gapp-components/components/display/EnvironmentSystemBar.vue";
import { mapGetters } from "vuex";

import CountryFlag from "vue-country-flag";

export default {
  name: "LayoutAuthenticated",
  components: {
    CountryFlag,
    EnvironmentSystemBar,
    SelectedProgram,
    SelectedProgramDropdown,
    LocaleDropdown,
    UserDropdown,
    TopMenu,
    Breadcrumbs,
    Footer
  },
  data: () => ({}),
  methods: {
    navigateToPortal() {
      this.$portal.navigateProgram(this.selectedProgram.id, true);
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram", "selectedCountry"]),
    isGappInternalUser() {
      return this.$privilege.isGappInternalUser();
    },
    environment() {
      return process.env;
    }
  }
};
</script>
